import amplitude from "amplitude-js";
import appConfig from "./app";

export const amplitudeInstance = amplitude.getInstance();

export const initAmplitude = async (deviceId: string) => {
  // get viewport width and height
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  amplitudeInstance.init(import.meta.env.VITE_AMPLITUDE);
  amplitudeInstance.setDeviceId(deviceId);
  amplitudeInstance.setVersionName(appConfig.BUILD_HASH);

  amplitudeInstance.setUserProperties({
    viewportWidth,
    viewportHeight,
  });
};
